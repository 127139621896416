<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <b-row>
            <b-col lg="6" md="6" cols="12" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <TextInput
                  v-model="form.name"
                  :label="$t('Case Name')"
                  :tooltipMessage="$t('Name')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col lg="6" md="6" cols="12" class="mb-1">
              <div class="form-group">
                <validation-provider
                  #default="{ errors }"
                  name="Case Status"
                  rules="required"
                >
                  <label class="input-label form-label"
                    ><span style="color: red">*</span>&nbsp;{{ $t("Status") }}
                  </label>
                  <multiselect
                    v-model="form.caseStatus"
                    :options="[
                      'queued',
                      'in-progress',
                      'failed',
                      'unsupported',
                      'done',
                      'in-approval',
                      'hidden',
                    ]"
                    :multiple="false"
                  >
                  </multiselect>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
            <b-col lg="6" md="6" cols="12" class="mb-1">
              <div class="form-group mt-2">
                <label class="form-label" for="cars">{{
                  $t("Approved")
                }}</label>

                <input
                  id="isApproved"
                  class="form-check-input-custom ml-2"
                  v-model="form.isApproved"
                  type="checkbox"
                  name="isApproved"
                />
              </div>
            </b-col>
            <b-col lg="6" md="6" cols="12" class="mb-1">
              <div class="form-group">
                <label class="input-label form-label"
                  >{{ $t("Company") }}
                </label>
                <div class="form-control disabled">
                  {{ companyName }}
                </div>
              </div>
            </b-col>
            <b-col lg="6" md="6" cols="12" class="mb-1">
              <label class="form-label" for="cars">{{ $t("Sender") }}</label>
              <div class="form-control disabled">
                <p v-if="caseUser">
                  {{ caseUser.first_name }}
                  <span>{{ caseUser.last_name }}</span>
                </p>
              </div>
            </b-col>
            <b-col lg="6" md="6" cols="12" class="mb-1">
              <label class="form-label" for="cars">{{
                $t("Sender Mail")
              }}</label>
              <div class="form-control disabled">
                <p v-if="caseUser">
                  <a :href="'mailto:' + caseUser.email">{{ caseUser.email }}</a>
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="form.files.upper" lg="6" md="6" cols="12" class="mb-1">
              <TextInput
                :type="'number'"
                v-model="form.amountReconstructedUpperTeeth"
                :label="$t('Amount of reconstructed Upper teeth')"
                :tooltipMessage="$t('Amount of reconstructed Upper teeth')"
                :required="true"
              />
              <small v-if="showErrorUpper" class="text-danger"
                >The Amount of reconstructed Upper teeth field is
                required</small
              >
            </b-col>
            <b-col v-if="form.files.lower" lg="6" md="6" cols="12" class="mb-1">
              <TextInput
                :type="'number'"
                v-model="form.amountReconstructedLowerTeeth"
                :label="$t('Amount of reconstructed Lower teeth')"
                :tooltipMessage="$t('Amount of reconstructed Lower teeth')"
                :required="true"
              />
              <small v-if="showErrorLower" class="text-danger"
                >The Amount of reconstructed Lower teeth field is
                required</small
              >
            </b-col>
            <b-col lg="12" md="12" cols="12" class="">
              <label class="input-label form-label">
                {{ $t("Comments") }}
              </label>
              <textarea class="form-control" v-model="form.details"></textarea>
            </b-col>
            <b-col
              lg="6"
              md="6"
              cols="12"
              class="mb-1 mt-3"
              v-if="form.results && form.results['upper_image']"
            >
              <div v-if="form.results && form.results['upper_image']" class="">
                <h4>{{ $t("Upper Jaw Rating") }}</h4>
                <StarRating
                  :value="(form.ratings && form.ratings.upper_case_rating) || 0"
                  @setRatingValue="setRatingValue($event, 'upper')"
                />
              </div>
            </b-col>
            <b-col
              lg="6"
              md="6"
              cols="12"
              class="mb-1 mt-3"
              v-if="form.results && form.results['lower_image']"
            >
              <div v-if="form.results && form.results['lower_image']" class="">
                <h4>{{ $t("Lower Jaw Rating") }}</h4>
                <StarRating
                  :value="(form.ratings && form.ratings.lower_case_rating) || 0"
                  @setRatingValue="setRatingValue($event, 'lower')"
                />
              </div>
            </b-col>
          </b-row>
          <div class="d-flex align-items-center justify-content-end gap-2 mt-3">
            <b-button
              variant="primary"
              @click="updateCaseData"
              class="d-flex align-items-center gap-1"
              :class="{ 'cursor-not-allowed': isFormInvalid }"
            >
              <feather-icon icon="PlusIcon" size="12" />
              <span>{{ $t("Update Case Data") }}</span>
            </b-button>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <b-row>
            <b-col md="12">
              <PreviewOriginalModel
                ref="previewOriginalRef"
                v-if="renderOriginalComponent"
                :upperFileId="form.files.upper"
                :lowerFileId="form.files.lower"
                :downloadUpper="form.files.upper_portal_original"
                :downloadLower="form.files.lower_portal_original"
                :upperMatrixIn="form.files.upper_matrixLocal.reg"
                :upperMatrixWorldIn="form.files.upper_matrixWorld.reg"
                :lowerMatrixIn="form.files.lower_matrixLocal.reg"
                :lowerMatrixWorldIn="form.files.lower_matrixWorld.reg"
              />
            </b-col>
            <b-col lg="6" md="6" cols="12" class="mb-1">
              <h4 class="input-label form-label mb-1">
                {{ $t("Upper Jaw Results") }}
              </h4>
              <!-- <input
                type="file"
                @change="addFile($event, 'upper', 'results')"
                accept=".stl, .obj"
              /> -->
              <div
                v-if="form.results && form.results['upper_rotated']"
                class="d-flex gap-2"
              >
                <div class="mt-1">
                  <b-button
                    variant="primary"
                    @click="
                      handlePreviewResultClick(
                        [form.results['upper_rotated']],
                        'Upper'
                      )
                    "
                  >
                    <span>{{ $t("Preview Result") }}</span>
                  </b-button>
                </div>
                <div class="mt-1">
                  <b-button
                    variant="primary"
                    @click="downloadFiles([form.results['upper_rotated']])"
                  >
                    <span>{{ $t("Download Result") }}</span>
                  </b-button>
                </div>
                <div class="mt-1">
                  <b-button
                    variant="primary"
                    @click="downloadFiles([form.results['upper']])"
                  >
                    <span>{{ $t("Download Deregistered") }}</span>
                  </b-button>
                </div>
              </div>
            </b-col>
            <b-col lg="6" md="6" cols="12" class="mb-1">
              <h4 class="input-label form-label mb-1">
                {{ $t("Lower Jaw Results") }}
              </h4>
              <!-- <input
                type="file"
                @change="addFile($event, 'lower', 'results')"
                accept=".stl, .obj"
              /> -->
              <div
                v-if="form.results && form.results['lower_rotated']"
                class="d-flex gap-2"
              >
                <div class="mt-1">
                  <b-button
                    variant="primary"
                    @click="
                      handlePreviewResultClick(
                        [form.results['lower_rotated']],
                        'Lower'
                      )
                    "
                  >
                    <span>{{ $t("Preview Result") }}</span>
                  </b-button>
                </div>
                <div class="mt-1">
                  <b-button
                    variant="primary"
                    @click="downloadFiles([form.results['lower_rotated']])"
                  >
                    <span>{{ $t("Download Result") }}</span>
                  </b-button>
                </div>
                <div class="mt-1">
                  <b-button
                    variant="primary"
                    @click="downloadFiles([form.results['lower']])"
                  >
                    <span>{{ $t("Download Deregistered") }}</span>
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end gap-2">
        <router-link
          to="/my-cases"
          class="d-flex align-items-center gap-1 btn btn-secondary"
        >
          <feather-icon icon="XIcon" size="12" />
          <span>{{ $t("Cancel") }}</span>
        </router-link>
        <b-button
          variant="primary"
          @click="updateCase"
          class="d-flex align-items-center gap-1"
          :class="{ 'cursor-not-allowed': isFormInvalid }"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Update & Recalculate Case") }}</span>
        </b-button>
      </div>
    </validation-observer>
    <div class="c-modal" v-show="showPreviewModal">
      <div class="c-modal-content">
        <div class="c-modal-header">
          <h3>{{ $t(`Preview ${previewJawType} Jaw`) }}</h3>
          <div class="x-icon" @click="closePreviewResultModal">
            <feather-icon size="16" icon="XIcon" />
          </div>
        </div>
        <div class="c-modal-body">
          <PreviewResultModel v-if="showPreviewModal" :results="file" />
        </div>
      </div>
    </div>
    <b-modal
      id="edit-tag-modal"
      v-model="showCreditModal"
      title="Not enough DentalTwin Coins"
      centered
      size="md"
      hide-footer
    >
      {{
        $t("You do not have enough DentalTwin Coins to recalculate the case")
      }}
      <div class="d-flex align-items-center justify-content-end mt-2">
        <b-button @click="showCreditModal = false" variant="primary">{{
          $t("Ok")
        }}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import * as THREE from "three";
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import Multiselect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-quill-editor";
import { required, email } from "@validations";
import StarRating from "@/components/elements/StarRating.vue";
import PreviewResultModel from "@/components/PreviewResultModel.vue";
import FileService from "@/services/file.service";
import PreviewOriginalModel from "./components/PreviewOriginalModel.vue";
export default {
  components: {
    TextInput,
    PageHeader,
    Multiselect,
    ValidationProvider,
    ValidationObserver,
    VueEditor,
    StarRating,
    PreviewResultModel,
    PreviewOriginalModel,
  },
  computed: {
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("case-list"),
          to: "/my-cases",
        },
        {
          text: this.$t("Edit"),
          active: true,
        },
      ];
    },
    isFormInvalid() {
      return (
        !this.form.amountReconstructedUpperTeeth ||
        !this.form.amountReconstructedLowerTeeth ||
        !this.form.name ||
        !this.form.caseStatus
      );
    },
    ...mapGetters("globalConfiguration", ["configuration"]),
    ...mapGetters("caseLists"),
  },
  data() {
    return {
      form: {
        name: "",
        details: "",
        caseStatus: "",
        results: {},
        ratings: {},
        files: {},
        isApproved: false,
        amountReconstructedUpperTeeth: "",
        amountReconstructedLowerTeeth: "",
      },
      isPaid: false,
      caseUser: {},
      costsPerUpperJaw: "",
      maximumOverallCosts: "",
      companyName: "",
      costsPerLowerJaw: "",
      recalculationFactor: "",
      showCreditModal: false,
      showPreviewModal: false,
      file: null,
      previewJawType: "",
      renderOriginalComponent: false,
      showErrorUpper: false,
      showErrorLower: false,
    };
  },
  watch: {
    "form.amountReconstructedUpperTeeth"(newValue) {
      this.showErrorUpper = false;
    },
    "form.amountReconstructedLowerTeeth"(newValue) {
      this.showErrorLower = false;
    },
  },
  async mounted() {
    await this.loadItems();

    this.getCredits();

    await this.$store.dispatch("globalConfiguration/show");
    this.costsPerUpperJaw = this.configuration.costsPerUpperJaw ?? "";
    this.costsPerLowerJaw = this.configuration.costsPerLowerJaw ?? "";
    this.maximumOverallCosts = this.configuration?.maximumOverallCosts ?? "";
    this.recalculationFactor = this.configuration.recalculationFactor ?? "";
    this.$store.commit("showLoader", false);
  },
  methods: {
    async getCredits() {
      const creditResponse = await this.$store.dispatch("customers/getCredits");
      this.credits = creditResponse?.data?.credits ?? 0;
    },
    async replicateCase() {
      const hasPermission = this.$can(
        "recalculate-case-from-backoffice.create"
      );
      if (!hasPermission) {
        var sumCredits = 0;
        if (this.form.files["upper"] != null) {
          sumCredits =
            parseFloat(this.costsPerUpperJaw) *
            parseFloat(this.form.amountReconstructedUpperTeeth);
        }
        if (this.form.files["lower"] != null) {
          sumCredits =
            sumCredits +
            parseFloat(this.costsPerLowerJaw) *
              parseFloat(this.form.amountReconstructedlowerTeeth);
        }

        if (
          this.maximumOverallCosts != "" &&
          sumCredits > parseFloat(this.maximumOverallCosts)
        ) {
          sumCredits = parseFloat(this.maximumOverallCosts);
        }
        if (this.isPaid) {
          if (this.recalculationFactor)
            sumCredits = sumCredits * parseFloat(this.recalculationFactor);
        }
        if (sumCredits > parseFloat(this.credits)) {
          this.showCreditModal = true;
          return;
        }
      }
      const payload = {
        caseId: this.$route.params.id,
        extensions: [{ id: "opg-to-3d" }],
        newCase: false,
      };

      await this.$store
        .dispatch("caseLists/replicateCase", payload)
        .finally(() => {
          this.getCredits();
          this.loadItems();
        });
    },
    async loadItems() {
      this.renderOriginalComponent = false;
      await this.$store
        .dispatch("caseLists/show", this.$route.params.id)
        .then(async (res) => {
          var response = res?.data?.data;
          this.companyName = response?.companyName ?? "-";
          this.form.name = response?.name ?? "";
          this.form.amountReconstructedUpperTeeth =
            response?.amountReconstructedUpperTeeth;
          this.form.amountReconstructedLowerTeeth =
            response?.amountReconstructedLowerTeeth;
          this.form.details = response?.details ?? "";
          this.form.caseStatus = response?.caseStatus ?? "";
          this.form.results = response?.results ?? {};
          this.form.ratings = response?.ratings ?? {};
          this.form.files = response?.files ?? {};
          this.form.isApproved = response?.isApproved;
          this.isPaid = response?.isPaid;
          await this.$store
            .dispatch("auth/showUser", {
              id: response?.user_id,
            })
            .then(async (res) => {
              this.caseUser = res?.data ?? {};
            });
        });
      this.renderOriginalComponent = true;
    },
    downloadFiles(array) {
      FileService.downloadFiles(array);
    },
    addFile(event, jawType, fileType) {
      try {
        this.$store.commit("showLoader", true);
        const file = event.target.files[0];

        FileService.uploadFile(file, { visibility: 0 }, 0, 0.33)
          .then((response) => {
            this.$store.commit("showLoader", false);
            this.$store.dispatch(
              `caseLists/${
                fileType === "results" ? "saveResults" : "save-files"
              }`,
              {
                caseId: this.$route.params.id,
                ...(jawType == "upper"
                  ? { upper: response?.data[0]?.id }
                  : { lower: response?.data[0]?.id }),
              }
            );
          })
          .finally(async () => {
            await this.loadItems();
            this.$store.commit("showLoader", false);
          });
      } catch (e) {}
    },
    closePreviewResultModal() {
      this.showPreviewModal = false;
    },
    handlePreviewResultClick(array, jawType) {
      this.file = array;
      this.previewJawType = jawType;
      this.showPreviewModal = true;
    },
    async setRatingValue(event, jawType) {
      await this.$store.dispatch("caseLists/saveRating", {
        caseId: this.$route.params.id,
        ...(jawType === "upper"
          ? { upperCaseRating: event }
          : { lowerCaseRating: event }),
      });
    },
    async updateCase() {
      if (
        this.form.files.upper &&
        (!this.form.amountReconstructedUpperTeeth ||
          this.form.amountReconstructedUpperTeeth.trim() === "")
      ) {
        this.showErrorUpper = true;
        return; // Stop execution if amountUpper is null, empty, or just spaces
      }
      if (
        this.form.files.lower &&
        (!this.form.amountReconstructedLowerTeeth ||
          this.form.amountReconstructedLowerTeeth.trim() === "")
      ) {
        this.showErrorLower = true;
        return; // Stop execution if amountUpper is null, empty, or just spaces
      }
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          this.$store.commit("showLoader", true);
          await this.$nextTick();
          setTimeout(async () => {
            try {
              let uploadedIds = { upperId: null, lowerId: null };
              if (this.$refs.previewOriginalRef) {
                uploadedIds =
                  await this.$refs.previewOriginalRef.uploadData2DTo3D();
              }

              let upperId = uploadedIds.upperId?.id || this.form.files.upper;
              let lowerId = uploadedIds.lowerId?.id || this.form.files.lower;

              const payload = {
                caseId: this.$route.params.id,
                upper: upperId,
                lower: lowerId,
                upper_rotation: uploadedIds?.upper_rotation,
                upper_translation: uploadedIds?.upper_translation,
                upper_matrixWorld: uploadedIds?.upper_matrixWorld,
                upper_quaternion: uploadedIds?.upper_quaternion,
                upper_scale: uploadedIds?.upper_scale,
                upper_matrixLocal: uploadedIds?.upper_matrixLocal,
                lower_rotation: uploadedIds?.lower_rotation,
                lower_translation: uploadedIds?.lower_translation,
                lower_matrixWorld: uploadedIds?.lower_matrixWorld,
                lower_quaternion: uploadedIds?.lower_quaternion,
                lower_scale: uploadedIds?.lower_scale,
                lower_matrixLocal: uploadedIds?.lower_matrixLocal,
              };

              await this.$store.dispatch("caseLists/saveFiles", payload);
              await this.$store.dispatch("caseLists/update", {
                id: this.$route.params.id,
                data: {
                  ...this.form,
                },
              });
              if (this.form.isApproved == true) {
                this.form.caseStatus = "done";
              }
              this.replicateCase();
              this.$store.commit("showLoader", false);
            } catch (e) {
              console.error(e);
            } finally {
              this.$store.commit("showLoader", false);
            }
          }, 0);
        }
      });
    },
    async updateCaseData() {
      if (
        this.form.files.upper &&
        (!this.form.amountReconstructedUpperTeeth ||
          this.form.amountReconstructedUpperTeeth.trim() === "")
      ) {
        this.showErrorUpper = true;
        return; // Stop execution if amountUpper is null, empty, or just spaces
      }
      if (
        this.form.files.lower &&
        (!this.form.amountReconstructedLowerTeeth ||
          this.form.amountReconstructedLowerTeeth.trim() === "")
      ) {
        this.showErrorLower = true;
        return; // Stop execution if amountUpper is null, empty, or just spaces
      }
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          this.$store.commit("showLoader", true);
          await this.$nextTick();
          setTimeout(async () => {
            try {
              const formData = { ...this.form };
              delete formData.files;
              delete formData.results;

              await this.$store.dispatch("caseLists/update", {
                id: this.$route.params.id,
                data: formData,
              });
              if (this.form.isApproved == true) {
                this.form.caseStatus = "done";
              }
              this.$store.commit("showLoader", false);
            } catch (e) {
              console.error(e);
            } finally {
              this.$store.commit("showLoader", false);
            }
          }, 0);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}

.news-image {
  margin-top: 10px;
  img {
    width: 200px;
    object-fit: contain;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 5px;
    border-radius: 5px;
  }
}
</style>
