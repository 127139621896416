<template>
  <div class="mycases create-case">
    <div class="pageheader">
      <h2>{{ $t("Original Jaws") }}</h2>
    </div>
    <div class="card">
      <div class="card-body">
        <section id="2d-to-3d-cards">
          <!-- LOWER JAW CONTROLS -->
          <div
            v-if="isSelectedJaw === 'lower'"
            class="d-flex align-items-center mb-1 gap-2 d-modal-meta"
          >
            <div class="d-flex align-items-center gap-1">
              <label>{{ $t("Select Size") }}:</label>
              <input
                type="range"
                min="0.1"
                max="3"
                step="0.1"
                v-model="lowerSize"
              />
            </div>
            <button class="btn btn-primary" @click="rotateBtn('lower')">
              <span>{{ $t("Rotate") }}</span>
            </button>
            <button class="btn btn-primary" @click="translateBtn('lower')">
              <span>{{ $t("Translate") }}</span>
            </button>
            <div class="d-flex align-items-center gap-1">
              <label for="sensitivityInput"
                >{{ $t("Control Sensitivity") }}:</label
              >
              <input
                type="range"
                min="0.1"
                max="5"
                step="0.1"
                v-model="sensitivity"
              />
            </div>
            <!-- Radio controls -->
            <div class="checkbox-group">
              <input
                type="radio"
                v-model="selectedControl"
                name="control-selection"
                value="orbit"
                class="checkbox-input"
                @change="switchControls"
                id="orbit"
              />
              <label for="orbit" class="checkbox-label">{{
                $t("Orbit")
              }}</label>
            </div>
            <div class="checkbox-group">
              <input
                type="radio"
                class="checkbox-input"
                id="trackball"
                v-model="selectedControl"
                name="control-selection"
                value="trackball"
                @change="switchControls"
              />
              <label for="trackball" class="checkbox-label">{{
                $t("Trackball")
              }}</label>
            </div>
            <div class="checkbox-group">
              <input
                type="radio"
                class="checkbox-input"
                id="fly"
                v-model="selectedControl"
                name="control-selection"
                value="fly"
                @change="switchControls"
              />
              <label for="fly" class="checkbox-label">{{ $t("Fly") }}</label>
            </div>
          </div>

          <!-- UPPER JAW CONTROLS -->
          <div
            v-if="isSelectedJaw === 'upper'"
            class="d-flex align-items-center mb-1 gap-2 d-modal-meta"
          >
            <div class="d-flex align-items-center gap-1">
              <label>{{ $t("Select Size") }}:</label>
              <input
                type="range"
                min="0.1"
                max="3"
                step="0.1"
                v-model="upperSize"
              />
            </div>
            <button class="btn btn-primary" @click="rotateBtn('upper')">
              <span>{{ $t("Rotate") }}</span>
            </button>
            <button class="btn btn-primary" @click="translateBtn('upper')">
              <span>{{ $t("Translate") }}</span>
            </button>
            <div class="d-flex align-items-center gap-1">
              <label for="sensitivityInputLower">
                {{ $t("Control Sensitivity") }}:
              </label>
              <input
                type="range"
                min="0.1"
                max="5"
                step="0.1"
                v-model="sensitivityUpper"
              />
            </div>
            <!-- Radio controls -->
            <div class="checkbox-group">
              <input
                type="radio"
                v-model="selectedControlUpper"
                name="control-selection-uppers"
                value="orbit"
                class="checkbox-input"
                @change="switchControlsUpper"
                id="orbitUpper"
              />
              <label for="orbitUpper" class="checkbox-label">{{
                $t("Orbit")
              }}</label>
            </div>
            <div class="checkbox-group">
              <input
                type="radio"
                class="checkbox-input"
                id="trackballUpper"
                v-model="selectedControlUpper"
                name="control-selection-upper"
                value="trackball"
                @change="switchControlsUpper"
              />
              <label for="trackballUpper" class="checkbox-label">
                {{ $t("Trackball") }}
              </label>
            </div>
            <div class="checkbox-group">
              <input
                type="radio"
                class="checkbox-input"
                id="flyUpper"
                v-model="selectedControlUpper"
                name="control-selection-upper"
                value="fly"
                @change="switchControlsUpper"
              />
              <label for="flyUpper" class="checkbox-label">{{
                $t("Fly")
              }}</label>
            </div>
          </div>

          <!-- TWO-CANVAS LAYOUT -->
          <b-row>
            <b-col md="7" xl="7">
              <div class="d-upload-dropzone cursor-pointer">
                <!-- LOWER JAW CANVAS -->
                <div
                  class="lower-card"
                  :class="{ active: isSelectedJaw === 'lower' }"
                  ref="card"
                >
                  <canvas
                    id="canvas"
                    class="upper-canvas"
                    v-show="isSelectedJaw === 'lower'"
                  ></canvas>
                </div>

                <!-- UPPER JAW CANVAS -->
                <div class="upper-card" ref="cardUpper">
                  <canvas
                    id="canvasUpper"
                    class="upper-canvas"
                    v-show="isSelectedJaw === 'upper'"
                  ></canvas>
                </div>

                <div
                  class="dropzone-check"
                  :class="isSelectedJaw ? 'active' : ''"
                >
                  <div class="checkbox-group">
                    <input
                      type="radio"
                      class="checkbox-input"
                      id="upper"
                      v-model="isSelectedJaw"
                      name="jaw-selection"
                      value="upper"
                      :checked="isSelectedJaw === 'upper'"
                    />
                    <label for="upper" class="checkbox-label">
                      {{ $t("Show Upper Jaw") }}
                    </label>
                  </div>
                  <div class="checkbox-group">
                    <input
                      type="radio"
                      class="checkbox-input"
                      id="lower"
                      v-model="isSelectedJaw"
                      name="jaw-selection"
                      value="lower"
                      :checked="isSelectedJaw === 'lower'"
                    />
                    <label for="lower" class="checkbox-label">
                      {{ $t("Show Lower Jaw") }}
                    </label>
                  </div>
                </div>
              </div>
            </b-col>

            <!-- RIGHT COLUMN: JAW FILE UPLOADS, ETC. -->
            <b-col md="5" xl="5">
              <div class="mb-2">
                <h4>{{ $t("Upper Jaw") }}</h4>
                <div class="jaws-dropdown">
                  <div
                    class="custom-dropdzone"
                    @dragover.prevent="onDragOver"
                    @dragleave="onDragLeave"
                    @drop.prevent="onDrop($event, 'upper')"
                    :class="{ 'is-dragover': isDragOver }"
                  >
                    <div v-if="!upperJawFile" class="dropzone-content">
                      <div class="icon">
                        <ElementIcon icon="uploadIcon" />
                      </div>
                      <div>
                        <h3>{{ $t("Select a file or drag and drop here") }}</h3>
                        <p>.obj, .ply, .stl, file size no more than 50MB</p>
                      </div>
                    </div>
                    <div
                      v-else
                      class="uploaded-file-name d-flex align-items-center"
                    >
                      <p class="mr-2">
                        {{ $t("Uploaded Upper Jaw") }}: {{ upperJawFile.name }}
                      </p>
                      <feather-icon
                        class="cursor-pointer"
                        @click="deleteUpperJawFile()"
                        size="16"
                        icon="TrashIcon"
                      />
                    </div>
                    <feather-icon
                      v-if="downloadUpper"
                      icon="DownloadIcon"
                      class="cursor-pointer ml-5"
                      size="25"
                      @click="downloadUpperJaw"
                    />
                  </div>
                  <input
                    type="file"
                    ref="fileUpperJawInput"
                    @change="previewJaw('upper', $event)"
                    accept=".obj, .stl, .ply"
                    style="display: none"
                  />
                  <button
                    class="btn btn-secondary"
                    @click="openUpperJawFilePicker"
                  >
                    <ElementIcon icon="folderIcon" />
                    <span>{{ $t("Browse") }}</span>
                  </button>
                </div>
                <p v-if="upperJawFile">
                  {{ $t("The cost for upper jaw is:") }} {{ costsPerUpperJaw }}
                  {{ $t("DentalTwin Coins") }}
                </p>
              </div>

              <h2 class="mb-2">{{ $t("AND") }} / {{ $t("OR") }}</h2>

              <div class="mb-2">
                <h4>{{ $t("Lower Jaw") }}</h4>
                <div class="jaws-dropdown">
                  <div
                    class="custom-dropdzone"
                    @dragover.prevent="onDragOver"
                    @dragleave="onDragLeave"
                    @drop.prevent="onDrop($event, 'lower')"
                    :class="{ 'is-dragover': isDragOver }"
                  >
                    <div v-if="!lowerJawFile" class="dropzone-content">
                      <div class="icon">
                        <ElementIcon icon="uploadIcon" />
                      </div>
                      <div>
                        <h3>{{ $t("Select a file or drag and drop here") }}</h3>
                        <p>.obj, .ply, .stl, file size no more than 50MB</p>
                      </div>
                    </div>
                    <div
                      v-else
                      class="uploaded-file-name d-flex align-items-center"
                    >
                      <p class="mr-2">
                        {{ $t("Uploaded Lower Jaw") }}: {{ lowerJawFile.name }}
                      </p>
                      <feather-icon
                        class="cursor-pointer"
                        @click="deleteLowerJawFile"
                        size="16"
                        icon="TrashIcon"
                      />
                    </div>
                    <feather-icon
                      v-if="downloadLower"
                      icon="DownloadIcon"
                      class="cursor-pointer ml-5"
                      @click="downloadLowerJaw"
                      size="25"
                    />
                  </div>
                  <input
                    type="file"
                    ref="fileLowerJawInput"
                    @change="previewJaw('lower', $event)"
                    accept=".obj, .stl, .ply"
                    style="display: none"
                  />
                  <button
                    class="btn btn-secondary"
                    @click="openLowerJawFilePicker"
                  >
                    <ElementIcon icon="folderIcon" />
                    <span>{{ $t("Browse") }}</span>
                  </button>
                </div>
                <p v-if="lowerJawFile">
                  {{ $t("The cost for lower jaw is:") }} {{ costsPerLowerJaw }}
                  {{ $t("DentalTwin Coins") }}
                </p>
              </div>
            </b-col>
          </b-row>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import CookieButton from "@/components/elements/CookieButton.vue";
import {
  BRow,
  BCol,
  BFormInput,
  BFormRadio,
  BCard,
  BCardText,
  BCardTitle,
  BImg,
  BFormFile,
  BFormGroup,
  BButton,
  BModal,
} from "bootstrap-vue";
import * as THREE from "three";
import NotificationService from "@/services/notification.service";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls.js";
import { FlyControls } from "three/examples/jsm/controls/FlyControls.js";
import { TransformControls } from "three/examples/jsm/controls/TransformControls.js";
import { STLExporter } from "three/examples/jsm/exporters/STLExporter.js";
import FileService from "@/services/file.service";
import TextInput from "@/components/TextInput.vue";
import TextareaInput from "@/components/TextareaInput.vue";
import ElementIcon from "@/components/elements/Icon.vue";
import { mapGetters } from "vuex";
import { addLights, loadModel } from "@/utils/3d";

export default {
  name: "OptimizedUploader",
  components: {
    BRow,
    BCol,
    BFormRadio,
    BCard,
    BCardText,
    BCardTitle,
    BImg,
    BFormFile,
    BFormGroup,
    BButton,
    BModal,
    TextInput,
    TextareaInput,
    ElementIcon,
    CookieButton,
  },
  computed: {
    ...mapGetters(["showLoader"])
  },
  props: {
    upperFileId: {
      type: String,
      default: null,
    },
    lowerFileId: {
      type: String,
      default: null,
    },
    downloadUpper: {
      type: String,
      default: null,
    },
    downloadLower: {
      type: String,
      default: null,
    },
    upperMatrixIn: {
      type: Array,
      default: [],
    },
    upperMatrixWorldIn: {
      type: Array,
      default: [],
    },
    lowerMatrixIn: {
      type: Array,
      default: [],
    },
    lowerMatrixWorldIn: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      // Keep only small/primitive data in reactivity
      caseName: "",
      caseDetails: "",
      costsPerLowerJaw: 0,
      costsPerUpperJaw: 0,
      credits: 0,
      showModal: false,
      sumCredits: 0,

      lowerJawFile: null,
      upperJawFile: null,
      upperMatrixIn: [],
      upperMatrixWorldIn: [],
      lowerMatrixIn: [],
      lowerMatrixWorldIn: [],

      // Transform data
      upperJawFileRotation: { orig: {}, reg: {} },
      lowerJawFileRotation: { orig: {}, reg: {} },
      upperJawFileTranslation: { orig: {}, reg: {} },
      lowerJawFileTranslation: { orig: {}, reg: {} },
      upperJawFileMatrixWorld: { orig: [], reg: [] },
      lowerJawFileMatrixWorld: { orig: [], reg: [] },
      upperJawFileQuaternion: { orig: {}, reg: {} },
      lowerJawFileQuaternion: { orig: {}, reg: {} },
      upperJawFileScale: { orig: {}, reg: {} },
      lowerJawFileScale: { orig: {}, reg: {} },
      upperJawFileMatrix: { orig: [], reg: [] },
      lowerJawFileMatrix: { orig: [], reg: [] },

      isSelectedJaw: "",
      isDragOver: false,
      isErrorMessage: false,
      termsAndConditions: false,

      // Sliders
      sensitivity: 2,
      sensitivityUpper: 1,
      selectedControl: "trackball",
      selectedControlUpper: "trackball",
      upperSize: 1,
      lowerSize: 1,
    };
  },
  watch: {
    upperSize(val) {
      if (this._upperGroundTruthModel) {
        this._upperGroundTruthModel.scale.setScalar(val);
      }
    },
    lowerSize(val) {
      if (this._lowerGroundTruthModel) {
        this._lowerGroundTruthModel.scale.setScalar(val);
      }
    },
    // Sensitivity watchers
    sensitivity(val) {
      this._updateControlsSensitivity(
        {
          orbitControls: this._orbitControls,
          trackballControls: this._trackballControls,
          flyControls: this._flyControls,
        },
        parseFloat(val)
      );
    },
    sensitivityUpper(val) {
      this._updateControlsSensitivity(
        {
          orbitControls: this._orbitControlsUpper,
          trackballControls: this._trackballControlsUpper,
          flyControls: this._flyControlsUpper,
        },
        parseFloat(val)
      );
    },
  },
  async mounted() {
    this.$store.commit("showLoader", true);
    try {
      // 2) Initialize the TWO Scenes
      this._initLowerScene();
      this._initUpperScene();

      // 3) Start animation loops
      this._clock = new THREE.Clock();
      this._clockUpper = new THREE.Clock();
      this._animateLower();
      this._animateUpper();

      await this._loadFiles();

      // 5) Listen to window events
      window.addEventListener("resize", this._onWindowResizeLower);
      window.addEventListener("resize", this._onWindowResizeUpper);
      window.addEventListener("keydown", this._handleKeyDown);
      window.addEventListener("keyup", this._handleKeyUp);

      if (!this.isSelectedJaw) {
        this.isSelectedJaw = "lower";
      }
      this.$store.commit("showLoader", false);
    } catch (err) {
      console.error(err);
      this.$store.commit("showLoader", false);
    }
  },
  beforeDestroy() {
    // Remove event listeners
    window.removeEventListener("resize", this._onWindowResizeLower);
    window.removeEventListener("resize", this._onWindowResizeUpper);
    window.removeEventListener("keydown", this._handleKeyDown);
    window.removeEventListener("keyup", this._handleKeyUp);

    // Dispose controls & renderers
    if (this._orbitControls) this._orbitControls.dispose();
    if (this._trackballControls) this._trackballControls.dispose();
    if (this._flyControls) this._flyControls.dispose();
    if (this._transformControlLower) this._transformControlLower.dispose();
    if (this._renderer) this._renderer.dispose();

    if (this._orbitControlsUpper) this._orbitControlsUpper.dispose();
    if (this._trackballControlsUpper) this._trackballControlsUpper.dispose();
    if (this._flyControlsUpper) this._flyControlsUpper.dispose();
    if (this._transformControlUpper) this._transformControlUpper.dispose();
    if (this._rendererUpper) this._rendererUpper.dispose();
  },
  methods: {
    downloadFiles(array) {
      FileService.downloadFiles(array);
    },
    /**
     * Download the upper jaw if the prop-based file ID is present
     */
    downloadUpperJaw() {
      if (!this.downloadUpper) return;
      this.downloadFiles([this.downloadUpper]);
    },
    /**
     * Download the lower jaw if the prop-based file ID is present
     */
    downloadLowerJaw() {
      if (!this.downloadLower) return;
      this.downloadFiles([this.downloadLower]);
    },
    // -----------------------------------------------
    //  LOAD COSTS & GROUND TRUTH
    // -----------------------------------------------
    async _loadFiles() {
      const response = await this.$store.dispatch("caseLists/getCreditCosts");
      const upperExtension = response?.data?.data?.upperExtension ?? "stl";
      const lowerExtension = response?.data?.data?.lowerExtension ?? "stl";
      const upperId = response?.data?.data?.upperJawTemplate ?? "";
      const lowerId = response?.data?.data?.lowerJawTemplate ?? "";
      let upperJawFile, lowerJawFile;
      if (upperId) {
        upperJawFile = await FileService.getFileById(upperId);
      }
      if (lowerId) {
        lowerJawFile = await FileService.getFileById(lowerId);
      }

      if (upperJawFile) {
        this.isSelectedJaw = "upper";
        await this._previewGroundTruthModel(
          upperJawFile,
          upperExtension,
          this._userGroupUpper,
          "_upperGroundTruthModel"
        );
      } else {
        this.isSelectedJaw = "lower";
      }
      if (lowerJawFile) {
        await this._previewGroundTruthModel(
          lowerJawFile,
          lowerExtension,
          this._userGroupLower,
          "_lowerGroundTruthModel"
        );
      }
      if (this.upperFileId) {
        // Load user’s own upper file
        this.isSelectedJaw = "upper";
        await this._loadFileAndRender(
          this.upperFileId,
          this._userGroupUpper,
          "upper",
          "_userModelUpper",
          this._cameraUpper,
          this._rendererUpper,
          this._currentUpperControls
        );
      } else {
        this.isSelectedJaw = "lower";
      }
      if (this.lowerFileId) {
        this.isSelectedJaw = this.isSelectedJaw || "lower";
        await this._loadFileAndRender(
          this.lowerFileId,
          this._userGroupLower,
          "lower",
          "_userModelLower",
          this._cameraLower,
          this._renderer,
          this._currentLowerControls
        );
      }
    },
    async _previewGroundTruthModel(fileObj, extension, group, modelProp) {
      if (!fileObj || !fileObj.data) return;
      const arrayBuf = await fileObj.data.arrayBuffer();
      const newModel = loadModel(arrayBuf, extension, {
        transparent: true,
        opacity: 0.5,
        side: THREE.DoubleSide,
        color: 0x05fffa,
      });
      // Remove old if any
      if (this[modelProp]) {
        group.remove(this[modelProp]);
      }
      group.add(newModel);
      this[modelProp] = newModel; // store reference
    },
    async _loadFileAndRender(
      fileId,
      group,
      jawName,
      modelProp,
      camera,
      renderer,
      controls
    ) {
      try {
        // 1) Fetch file
        const fileObj = await FileService.getFileById(fileId);

        this[jawName] = {};
        this[jawName].fileName = fileObj.name;

        // 2) Convert to ArrayBuffer
        const arrayBuf = await fileObj.data.arrayBuffer();

        // 3) Derive extension
        const extension = fileObj.name?.split(".").pop().toLowerCase() || "stl";

        // 4) Remove old model if exists
        if (this[modelProp]) {
          group.remove(this[modelProp]);
          this[modelProp] = null;
        }

        // 5) Load new model
        const newModel = loadModel(arrayBuf, extension, {
          side: THREE.DoubleSide,
          color: 0xb1bcbc,
        });
        group.add(newModel);
        this[modelProp] = newModel;

        // 7) Add transform controls
        const transformControlKey =
          modelProp === "_userModelUpper"
            ? "_transformControlUpper"
            : "_transformControlLower";

        if (this[transformControlKey]) {
          group.remove(this[transformControlKey]);
          this[transformControlKey].dispose();
          this[transformControlKey] = null;
        }
        const tControls = new TransformControls(camera, renderer.domElement);
        tControls.attach(newModel);
        group.add(tControls);
        this[transformControlKey] = tControls;

        // Ensure orbit/trackball/fly controls are disabled while transforming
        tControls.addEventListener("dragging-changed", (evt) => {
          controls.enabled = !evt.value;
        });
        // Re-render on transform changes
        tControls.addEventListener("change", () => {
          renderer.render(
            group === this._userGroupUpper
              ? this._sceneUpper
              : this._sceneLower,
            camera
          );
        });
      } catch (err) {
        console.error("Failed to load file by ID: ", fileId, err);
      }
    },

    // -----------------------------------------------
    //  INIT LOWER SCENE
    // -----------------------------------------------
    _initLowerScene() {
      this._sceneLower = new THREE.Scene();
      addLights(this._sceneLower);

      const card = this.$refs.card;
      const w = card.clientWidth,
        h = card.clientHeight;

      this._cameraLower = new THREE.PerspectiveCamera(60, w / h, 0.1, 1000);
      this._cameraLower.position.set(0, 0, 100);

      this._renderer = new THREE.WebGLRenderer({
        antialias: true,
        canvas: document.getElementById("canvas"),
      });
      this._renderer.setSize(w, h);

      // Controls
      this._orbitControls = new OrbitControls(
        this._cameraLower,
        this._renderer.domElement
      );
      this._trackballControls = new TrackballControls(
        this._cameraLower,
        this._renderer.domElement
      );
      this._flyControls = new FlyControls(
        this._cameraLower,
        this._renderer.domElement
      );
      this._flyControls.movementSpeed = 10;
      this._flyControls.rollSpeed = Math.PI / 24;
      this._flyControls.dragToLook = true;
      this._flyControls.autoForward = false;
      this._flyControls.enabled = false;

      this._trackballControls.screen.height = h;
      this._trackballControls.screen.width = w;
      this._trackballControls.rotateSpeed = 2;
      this._currentLowerControls = this._trackballControls;
      this._trackballControls.enabled = true;

      // Apply default sensitivity
      this._updateControlsSensitivity(
        {
          orbitControls: this._orbitControls,
          trackballControls: this._trackballControls,
          flyControls: this._flyControls,
        },
        2
      );

      // User group
      this._userGroupLower = new THREE.Group();
      this._sceneLower.add(this._userGroupLower);

      // By default, trackball control for lower
      this.switchControls();
    },
    // -----------------------------------------------
    //  INIT UPPER SCENE
    // -----------------------------------------------
    _initUpperScene() {
      this._sceneUpper = new THREE.Scene();
      addLights(this._sceneUpper);

      const cardUpper = this.$refs.cardUpper;
      const w = cardUpper.clientWidth,
        h = cardUpper.clientHeight;

      this._cameraUpper = new THREE.PerspectiveCamera(60, w / h, 0.1, 1000);
      this._cameraUpper.position.set(0, 0, -100);

      this._rendererUpper = new THREE.WebGLRenderer({
        antialias: true,
        canvas: document.getElementById("canvasUpper"),
      });
      this._rendererUpper.setSize(w, h);

      // Controls
      this._orbitControlsUpper = new OrbitControls(
        this._cameraUpper,
        this._rendererUpper.domElement
      );
      this._trackballControlsUpper = new TrackballControls(
        this._cameraUpper,
        this._rendererUpper.domElement
      );
      this._flyControlsUpper = new FlyControls(
        this._cameraUpper,
        this._rendererUpper.domElement
      );
      this._flyControlsUpper.movementSpeed = 10;
      this._flyControlsUpper.rollSpeed = Math.PI / 24;
      this._flyControlsUpper.dragToLook = true;
      this._flyControlsUpper.autoForward = false;
      this._flyControlsUpper.enabled = false;

      this._trackballControlsUpper.screen.height = w;
      this._trackballControlsUpper.screen.width = h;
      this._trackballControlsUpper.rotateSpeed = 2;
      this._currentUpperControls = this._trackballControlsUpper;
      this._trackballControlsUpper.enabled = true;

      this._updateControlsSensitivity(
        {
          orbitControls: this._orbitControlsUpper,
          trackballControls: this._trackballControlsUpper,
          flyControls: this._flyControlsUpper,
        },
        2
      );

      // User group
      this._userGroupUpper = new THREE.Group();
      this._sceneUpper.add(this._userGroupUpper);

      this.switchControlsUpper();
    },

    // -----------------------------------------------
    //  ANIMATION LOOPS
    // -----------------------------------------------
    _animateLower() {
      requestAnimationFrame(this._animateLower);
      if (!this._clock) return; // in case of destroy

      const delta = this._clock.getDelta();
      if (this._currentLowerControls === this._flyControls) {
        this._flyControls.update(delta);
      } else {
        this._currentLowerControls.update();
      }
      this._renderer.render(this._sceneLower, this._cameraLower);
    },
    _animateUpper() {
      requestAnimationFrame(this._animateUpper);
      if (!this._clockUpper) return;

      const delta = this._clockUpper.getDelta();
      if (this._currentUpperControls === this._flyControlsUpper) {
        this._flyControlsUpper.update(delta);
      } else {
        this._currentUpperControls.update();
      }
      this._rendererUpper.render(this._sceneUpper, this._cameraUpper);
    },

    _onWindowResizeLower() {
      const card = this.$refs.card;
      if (!card) return;
      const w = card.clientWidth,
        h = card.clientHeight;
      if (this._cameraLower) {
        this._cameraLower.aspect = w / h;
        this._cameraLower.updateProjectionMatrix();
      }
      if (this._renderer) {
        this._renderer.setSize(w, h);
      }
    },
    _onWindowResizeUpper() {
      const cardUpper = this.$refs.cardUpper;
      if (!cardUpper) return;
      const w = cardUpper.clientWidth,
        h = cardUpper.clientHeight;
      if (this._cameraUpper) {
        this._cameraUpper.aspect = w / h;
        this._cameraUpper.updateProjectionMatrix();
      }
      if (this._rendererUpper) {
        this._rendererUpper.setSize(w, h);
      }
    },

    // -----------------------------------------------
    //  CONTROL SWITCH
    // -----------------------------------------------
    switchControls() {
      // For lower scene
      this._orbitControls.enabled = false;
      this._trackballControls.enabled = false;
      this._flyControls.enabled = false;

      switch (this.selectedControl) {
        case "orbit":
          this._orbitControls.enabled = true;
          this._currentLowerControls = this._orbitControls;
          break;
        case "trackball":
          this._trackballControls.enabled = true;
          this._currentLowerControls = this._trackballControls;
          break;
        case "fly":
          this._flyControls.enabled = true;
          this._currentLowerControls = this._flyControls;
          break;
      }
    },
    switchControlsUpper() {
      // For upper scene
      this._orbitControlsUpper.enabled = false;
      this._trackballControlsUpper.enabled = false;
      this._flyControlsUpper.enabled = false;

      switch (this.selectedControlUpper) {
        case "orbit":
          this._orbitControlsUpper.enabled = true;
          this._currentUpperControls = this._orbitControlsUpper;
          break;
        case "trackball":
          this._trackballControlsUpper.enabled = true;
          this._currentUpperControls = this._trackballControlsUpper;
          break;
        case "fly":
          this._flyControlsUpper.enabled = true;
          this._currentUpperControls = this._flyControlsUpper;
          break;
      }
    },
    _updateControlsSensitivity(ctrlSet, sensitivity) {
      ctrlSet.orbitControls.rotateSpeed = sensitivity;
      ctrlSet.orbitControls.zoomSpeed = sensitivity;
      ctrlSet.orbitControls.panSpeed = sensitivity / 2;

      ctrlSet.trackballControls.rotateSpeed = sensitivity;
      ctrlSet.trackballControls.zoomSpeed = sensitivity;
      ctrlSet.trackballControls.panSpeed = sensitivity / 2;

      ctrlSet.flyControls.movementSpeed = sensitivity * 10;
      ctrlSet.flyControls.rollSpeed = (sensitivity * Math.PI) / 24;
    },

    // -----------------------------------------------
    //  TRANSFORM (ROTATE/TRANSLATE)
    // -----------------------------------------------
    rotateBtn(jawType) {
      if (jawType === "upper") {
        if (this._transformControlUpper) {
          this._transformControlUpper.setMode("rotate");
        }
      } else {
        if (this._transformControlLower) {
          this._transformControlLower.setMode("rotate");
        }
      }
    },
    translateBtn(jawType) {
      if (jawType === "upper") {
        if (this._transformControlUpper) {
          this._transformControlUpper.setMode("translate");
        }
      } else {
        if (this._transformControlLower) {
          this._transformControlLower.setMode("translate");
        }
      }
    },
    readFileAsArrayBuffer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => resolve(event.target.result);
        reader.onerror = (err) => reject(err);
        reader.readAsArrayBuffer(file);
      });
    },
    async previewJaw(jawType, event) {
      try {
        this.$store.commit("showLoader", true);
        const file = event.target.files[0];
        if (!file) return;

        // 1) Store the raw File object for later upload
        if (jawType === "upper") {
          this.upperJawFile = file;
          this.isSelectedJaw = "upper";
        } else {
          this.lowerJawFile = file;
          this.isSelectedJaw = "lower";
        }

        // 2) Remove previously rendered model from scene (if any)
        if (jawType === "upper" && this._userModelUpper) {
          this._userGroupUpper.remove(this._userModelUpper);
          this._userModelUpper = null;
          this.upperMatrixIn=null;
          this.upperMatrixWorldIn=null;
        }
        if (jawType === "lower" && this._userModelLower) {
          this._userGroupLower.remove(this._userModelLower);
          this._userModelLower = null;
          this.lowerMatrixIn=null;
          this.lowerMatrixWorldIn=null;
        }

        // 3) Read file locally as ArrayBuffer
        const arrayBuf = await this.readFileAsArrayBuffer(file);

        // 4) Determine file extension
        const extension = file.name.split(".").pop().toLowerCase() || "stl";

        // 5) Load the new 3D model with local data
        const newModel = loadModel(arrayBuf, extension, {
          side: THREE.DoubleSide,
          color: 0xb1bcbc,
        });

        // 6) Add to the correct scene group
        if (jawType === "upper") {
          this._userModelUpper = newModel;
          this._userGroupUpper.add(newModel);
          // Re-attach transform controls if needed
          if (this._transformControlUpper) {
            this._userGroupUpper.remove(this._transformControlUpper);
            this._transformControlUpper.dispose();
            this._transformControlUpper = null;
          }
          const tControls = new TransformControls(
            this._cameraUpper,
            this._rendererUpper.domElement
          );
          tControls.attach(newModel);
          this._userGroupUpper.add(tControls);
          this._transformControlUpper = tControls;
          tControls.addEventListener("dragging-changed", (evt) => {
            this._currentUpperControls.enabled = !evt.value;
          });
        } else {
          this._userModelLower = newModel;
          this._userGroupLower.add(newModel);
          if (this._transformControlLower) {
            this._userGroupLower.remove(this._transformControlLower);
            this._transformControlLower.dispose();
            this._transformControlLower = null;
          }
          const tControls = new TransformControls(
            this._cameraLower,
            this._renderer.domElement
          );
          tControls.attach(newModel);
          this._userGroupLower.add(tControls);
          this._transformControlLower = tControls;
          tControls.addEventListener("dragging-changed", (evt) => {
            this._currentLowerControls.enabled = !evt.value;
          });
        }
      } catch (e) {
        console.error("Error in previewJaw:", e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    _centerObject(obj) {
      const box = new THREE.Box3().setFromObject(obj);
      const center = box.getCenter(new THREE.Vector3());
      obj.position.sub(center);
    },
    deleteUpperJawFile() {
      this.upperJawFile = null;
      if (this._userModelUpper) {
        this._userGroupUpper.remove(this._userModelUpper);
        this._userModelUpper = null;
        this.upperMatrixIn=null;
        this.upperMatrixWorldIn=null;
      }
    },
    deleteLowerJawFile() {
      this.lowerJawFile = null;
      if (this._userModelLower) {
        this._userGroupLower.remove(this._userModelLower);
        this._userModelLower = null;
        this.lowerMatrixIn=null;
        this.lowerMatrixWorldIn=null;
      }
    },
    openUpperJawFilePicker() {
      this.$refs.fileUpperJawInput.click();
    },
    openLowerJawFilePicker() {
      this.$refs.fileLowerJawInput.click();
    },

    // -----------------------------------------------
    //  KEYBOARD SHORTCUTS
    // -----------------------------------------------
    _handleKeyDown(event) {
      let ctrlKey = navigator.userAgent.includes("Mac") ? "Meta" : "Control";
      if (event.key === ctrlKey) {
        if (this._transformControlUpper) {
          this._transformControlUpper.setMode("rotate");
        }
        if (this._transformControlLower) {
          this._transformControlLower.setMode("rotate");
        }
      }
    },
    _handleKeyUp(event) {
      let ctrlKey = navigator.userAgent.includes("Mac") ? "Meta" : "Control";
      if (event.key === ctrlKey) {
        if (this._transformControlUpper) {
          this._transformControlUpper.setMode("translate");
        }
        if (this._transformControlLower) {
          this._transformControlLower.setMode("translate");
        }
      }
    },

    // -----------------------------------------------
    //  DRAG & DROP
    // -----------------------------------------------
    onDragOver() {
      this.isDragOver = true;
    },
    onDragLeave() {
      this.isDragOver = false;
    },
    onDrop(event, jawType) {
      this.isDragOver = false;
      const droppedFiles = event.dataTransfer.files;
  
      if (droppedFiles && droppedFiles.length > 0) {
        const allowedExtensions = ["stl", "obj", "ply"];
        const filteredFiles = Array.from(droppedFiles).filter(file => {
          const extension = file.name.split('.').pop().toLowerCase();
          return allowedExtensions.includes(extension);
        });

        if (filteredFiles.length === 0) {
          NotificationService.showError(
            "Only STL, OBJ, and PLY files are allowed."
          );
          return;
        }

        this.previewJaw(jawType, { target: { files: filteredFiles } });
      }
    },

    // -----------------------------------------------
    //  RELOAD & UPLOAD
    // -----------------------------------------------
    reloadPage() {
      window.location.reload();
    },

    async uploadData2DTo3D() {
      try {
        if (this._userModelUpper) {
          let upperMatrix = this.upperMatrixIn===null ? new THREE.Matrix4() : new THREE.Matrix4().fromArray(this.upperMatrixIn);
          let upperMatrixWorld = this.upperMatrixWorldIn===null ? new THREE.Matrix4() : new THREE.Matrix4().fromArray(this.upperMatrixWorldIn);
          
          this._userModelUpper.updateMatrix();
          this._userModelUpper.updateMatrixWorld();

          this.upperJawFileMatrix = {
            orig: [],
            reg: (upperMatrix.multiply(this._userModelUpper.matrix)).elements.slice()
          };

          this.upperJawFileMatrixWorld = {
            orig: [],
            reg: (upperMatrixWorld.multiply(this._userModelUpper.matrixWorld)).elements.slice()
          };

          this.upperJawFileRotation.reg.x = this._userModelUpper.rotation.x;
          this.upperJawFileRotation.reg.y = this._userModelUpper.rotation.y;
          this.upperJawFileRotation.reg.z = this._userModelUpper.rotation.z;

          this.upperJawFileTranslation.reg.x =
            this._userModelUpper.position.x;
          this.upperJawFileTranslation.reg.y =
            this._userModelUpper.position.y;
          this.upperJawFileTranslation.reg.z =
            this._userModelUpper.position.z;

          this.upperJawFileQuaternion.reg.x =
            this._userModelUpper.quaternion.x;
          this.upperJawFileQuaternion.reg.y =
            this._userModelUpper.quaternion.y;
          this.upperJawFileQuaternion.reg.z =
            this._userModelUpper.quaternion.z;
          this.upperJawFileQuaternion.reg.w =
            this._userModelUpper.quaternion.w;

          this.upperJawFileScale.reg.x = this._userModelUpper.scale.x;
          this.upperJawFileScale.reg.y = this._userModelUpper.scale.y;
          this.upperJawFileScale.reg.z = this._userModelUpper.scale.z;
        }

        if (this._userModelLower) {
          let lowerMatrix = this.lowerMatrixIn===null ? new THREE.Matrix4() : new THREE.Matrix4().fromArray(this.lowerMatrixIn);
          let lowerMatrixWorld = this.lowerMatrixWorldIn===null ? new THREE.Matrix4() : new THREE.Matrix4().fromArray(this.lowerMatrixWorldIn);

          console.log(lowerMatrix, lowerMatrixWorld, this._userModelLower.matrix, this._userModelLower.matrixWorld);
          console.log(lowerMatrix.multiply(this._userModelLower.matrix));

          this._userModelLower.updateMatrix();
          this._userModelLower.updateMatrixWorld();

          this.lowerJawFileMatrix = {
            orig: [],
            reg: (lowerMatrix.multiply(this._userModelLower.matrix)).elements.slice()
          };
            
          this.lowerJawFileMatrixWorld = {
            orig: [],
            reg: (lowerMatrixWorld.multiply(this._userModelLower.matrixWorld)).elements.slice()
          };

          this.lowerJawFileRotation.reg.x = this._userModelLower.rotation.x;
          this.lowerJawFileRotation.reg.y = this._userModelLower.rotation.y;
          this.lowerJawFileRotation.reg.z = this._userModelLower.rotation.z;

          this.lowerJawFileTranslation.reg.x =
            this._userModelLower.position.x;
          this.lowerJawFileTranslation.reg.y =
            this._userModelLower.position.y;
          this.lowerJawFileTranslation.reg.z =
            this._userModelLower.position.z;

          this.lowerJawFileQuaternion.reg.x =
            this._userModelLower.quaternion.x;
          this.lowerJawFileQuaternion.reg.y =
            this._userModelLower.quaternion.y;
          this.lowerJawFileQuaternion.reg.z =
            this._userModelLower.quaternion.z;
          this.lowerJawFileQuaternion.reg.w =
            this._userModelLower.quaternion.w;

          this.lowerJawFileScale.reg.x = this._userModelLower.scale.x;
          this.lowerJawFileScale.reg.y = this._userModelLower.scale.y;
          this.lowerJawFileScale.reg.z = this._userModelLower.scale.z;
        }
      
        let upperId = null;
        let lowerId = null;
        this.$store.commit("showLoader", true);
        const uploadPromises = [];
        let exporter = new STLExporter();

        if (this._userModelUpper) {
          // Export ASCII
          const asciiStl = exporter.parse(this._userModelUpper);
          const binaryData = this.convertASCIIToBinarySTL(asciiStl);

          // Create file
          let blob = new Blob([binaryData], {
            type: "application/octet-stream",
          });
          let fileUpper = new File([blob], this.upperJawFile?.name ?? "upper_input.stl", {
            type: "application/octet-stream",
          });
          // Upload, TODO: delete old file
          uploadPromises.push(
            await FileService.uploadFile(
              fileUpper,
              { visibility: 0 },
              0,
              0.33
            ).then((resp) => {
              upperId = resp.data[0];
            })
          );
        }

        if (this._userModelLower) {
          const asciiStl = exporter.parse(this._userModelLower);
          const binaryData = this.convertASCIIToBinarySTL(asciiStl);

          let blob = new Blob([binaryData], {
            type: "application/octet-stream",
          });
          let fileLower = new File([blob], this.lowerJawFile?.name ?? "lower_input.stl", {
            type: "application/octet-stream",
          });
          // TODO: delete old file
          uploadPromises.push(
            await FileService.uploadFile(
              fileLower,
              { visibility: 0 },
              0.33,
              0.33
            ).then((resp) => {
              lowerId = resp.data[0];
            })
          );
        }

        await Promise.all(uploadPromises);

        this.$store.commit("showLoader", false);

        return {
          upperId: upperId,
          lowerId: lowerId,
          upper_rotation: this.upperJawFileRotation,
          lower_rotation: this.lowerJawFileRotation,
          upper_translation: this.upperJawFileTranslation,
          lower_translation: this.lowerJawFileTranslation,
          upper_matrixWorld: this.upperJawFileMatrixWorld,
          lower_matrixWorld: this.lowerJawFileMatrixWorld,
          upper_quaternion: this.upperJawFileQuaternion,
          lower_quaternion: this.lowerJawFileQuaternion,
          upper_scale: this.upperJawFileScale,
          lower_scale: this.lowerJawFileScale,
          upper_matrixLocal: this.upperJawFileMatrix,
          lower_matrixLocal: this.lowerJawFileMatrix,
        };
      } catch (err) {
        console.error(err);
        this.$store.commit("showLoader", false);

        return {};
      }
    },
    convertASCIIToBinarySTL(asciiData) {
      // Same code from your original for ASCII->Binary
      const lines = asciiData.split("\n");
      const triangles = [];
      let normal = null;
      let vertices = [];

      for (let line of lines) {
        line = line.trim();
        if (line.startsWith("facet normal")) {
          const normalData = line.match(
            /facet normal\s+([\S]+)\s+([\S]+)\s+([\S]+)/
          );
          if (normalData) {
            normal = [
              parseFloat(normalData[1]),
              parseFloat(normalData[2]),
              parseFloat(normalData[3]),
            ];
          }
          vertices = [];
        } else if (line.startsWith("vertex")) {
          const vertexData = line.match(/vertex\s+([\S]+)\s+([\S]+)\s+([\S]+)/);
          if (vertexData) {
            vertices.push([
              parseFloat(vertexData[1]),
              parseFloat(vertexData[2]),
              parseFloat(vertexData[3]),
            ]);
          }
        } else if (line.startsWith("endfacet")) {
          if (normal && vertices.length === 3) {
            triangles.push({ normal, vertices });
          }
          normal = null;
          vertices = [];
        }
      }

      if (triangles.length === 0) {
        throw new Error("No triangles found in the STL file.");
      }

      const triangleCount = triangles.length;
      const totalSize = 80 + 4 + triangleCount * 50;
      const buffer = new ArrayBuffer(totalSize);
      const dataView = new DataView(buffer);
      let offset = 0;

      // 80-byte header
      for (let i = 0; i < 80; i++) {
        dataView.setUint8(offset++, 0);
      }

      // Triangle count
      dataView.setUint32(offset, triangleCount, true);
      offset += 4;

      // Write triangles
      for (const t of triangles) {
        for (const n of t.normal) {
          dataView.setFloat32(offset, n, true);
          offset += 4;
        }
        for (const v of t.vertices) {
          for (const val of v) {
            dataView.setFloat32(offset, val, true);
            offset += 4;
          }
        }
        dataView.setUint16(offset, 0, true);
        offset += 2;
      }
      return buffer;
    },
  },
  beforeRouteEnter(to, from, next) {
    document.body.classList.add("cookie-layout");
    next();
  },
  beforeRouteLeave(to, from, next) {
    document.body.classList.remove("cookie-layout");
    next();
  },
};
</script>

<style>
.lower-card,
.upper-card {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.lower-card.active {
  z-index: 1;
}

.upper-canvas {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  left: 0;
  top: 0;
}
</style>
